import DataService from "../services/tutorial.service";
import custom_os_aop from './custom_os_aop.variable';
import structuredClone from '@ungap/structured-clone';
var cloneDeep = require('lodash.clonedeep');


function arrangePersons(fullFetch,database,osoby_firmy,osoby_pridelenie1,osoby_pridelenie2,osoby_courses,osoby_datumy,allCourses,conditions_dicts){
    let personalProfiles = fullFetch && fullFetch===true && database.filter(function(table){return table['table_name'] === 'personal_details'})[0]['data']
    let companies = fullFetch && fullFetch===true && database.filter(function(table){return table['table_name'] === 'companies'})[0]['data']
    console.log('number of persons in the database1:',personalProfiles.length)

    // remove profiles that are not active
    personalProfiles = personalProfiles.filter(item=>item.active===true)

    // add filter for firmy
    const firmy = osoby_firmy
    // console.log('firmy to filter:',firmy, personalProfiles)
    if (firmy.length>0){
      personalProfiles = personalProfiles.filter(profile=>
        firmy.includes(profile["zamestnávateľ"])
      )
    }
    console.log('number of persons in the database2:',personalProfiles.length)
    // add filter pridelenie1
    if (osoby_pridelenie1.length>0){
      // console.log("pridelenie1",osoby_pridelenie1)
      const pridelenie1 = osoby_pridelenie1
      companies = companies.filter(company=>
        pridelenie1.includes(company.company_pridelenie1.split(" ")[1] + " " + company.company_pridelenie1.split(" ")[0])
      )
      // console.log('companies restricted:',companies)
      const companies_names = companies && companies.map(com => com.company_nazov)
      personalProfiles = personalProfiles.filter(profile=>
        companies_names.includes(profile["zamestnávateľ"])  
      )
    }

    console.log('number of persons in the database3:',personalProfiles.length)
    // add filter pridelenie2
    if (osoby_pridelenie2.length>0){
      // console.log("pridelenie2",osoby_pridelenie2)
      const pridelenie2 = osoby_pridelenie2
      companies = companies.filter(company=>
        pridelenie2.includes(company.company_pridelenie2.split(" ")[1] + " " + company.company_pridelenie2.split(" ")[0])
      )
      // console.log('companies restricted:',companies)
      const companies_names = companies && companies.map(com => com.company_nazov)
      personalProfiles = personalProfiles.filter(profile=>
        companies_names.includes(profile["zamestnávateľ"])  
      )
    }

    console.log('number of persons in the database4:',personalProfiles.length)
    //add filter kurzy
    let aliases = []
    if (osoby_courses.length>0){
      
      // list of courses selected by user
      const courses = osoby_courses
      
      // convert the above list to aliases
      allCourses.map(course=>{if (courses.includes(course.course_nazov)){aliases.push(course.course_alias)}})
    //   console.log('courses:',courses)
    //   aliases && console.log('aliases:',aliases)
      personalProfiles = personalProfiles.filter(person=>
        aliases.some(alias=>database.filter(table=>table['table_name']===alias+"s")[0]['data'].some(row=>row[alias+"_id"]===person.id && row[alias+"_active"] === true))
      )

      // take above personal profiles and for all aliases, check the dates
      personalProfiles.forEach((person,index)=>
        aliases.map(alias=>{
          const row = database.filter(table=>table['table_name']===alias+"s")[0]['data'].filter(row=>row[alias+"_id"]===person.id && row[alias+"_active"] === true)
        //   console.log('rowlength:',row)
          if (row.length>0){
            let doCheck = checkDates(person,row[0],alias,['neohraničené'],companies,allCourses,conditions_dicts)
            // console.log('doCheck22:',doCheck)
            if (doCheck[0] === true){
              const check_dates_dict = {
                'ZS': doCheck[1]['ZS'],
                // 'what': doCheck[1]['what'].join(', '),
                'which_OS': doCheck[1]['which_OS'].join(', '),
                'which_AOP': doCheck[1]['which_AOP'].join(', '),
                'when_OS': doCheck[1]['when_OS_next'].join(', '),
                'when_OS_last': doCheck[1]['when_OS_last'].join(', '),
                'when_AOP': doCheck[1]['when_AOP_next'].join(', '),
                'when_AOP_last': doCheck[1]['when_AOP_last'].join(', '),
                // 'whom': doCheck[1]['whom']
              }
              console.log('setting dict:',check_dates_dict,' person:',person)
              personalProfiles[index][alias+"_"+'which_OS'] = check_dates_dict['which_OS']
              personalProfiles[index][alias+"_"+'which_AOP'] = check_dates_dict['which_AOP']
              personalProfiles[index][alias+"_"+'when_OS'] = check_dates_dict['when_OS']
              personalProfiles[index][alias+"_"+'when_OS_last'] = check_dates_dict['when_OS_last']
              personalProfiles[index][alias+"_"+'when_AOP'] = check_dates_dict['when_AOP']
              personalProfiles[index][alias+"_"+'when_AOP_last'] = check_dates_dict['when_AOP_last']
              personalProfiles[index][alias+"_"+'ZS'] = check_dates_dict['ZS']
            }else{
              console.log('deadlines not met')
              
            }
          }else{
            // empty data
            const check_dates_dict = {
              'ZS': '',
              'which_OS': '',
              'which_AOP': '',
              'when_OS': '',
              'when_OS_last': '',
              'when_AOP': '',
              'when_AOP_last': '',
            }
            // console.log('setting dict:',check_dates_dict)
            // personalProfiles[index][alias] = check_dates_dict
            personalProfiles[index][alias+"_"+'which_OS'] = check_dates_dict['which_OS']
            personalProfiles[index][alias+"_"+'which_AOP'] = check_dates_dict['which_AOP']
            personalProfiles[index][alias+"_"+'when_OS'] = check_dates_dict['when_OS']
            personalProfiles[index][alias+"_"+'when_OS_last'] = check_dates_dict['when_OS_last']
            personalProfiles[index][alias+"_"+'when_AOP'] = check_dates_dict['when_AOP']
            personalProfiles[index][alias+"_"+'when_AOP_last'] = check_dates_dict['when_AOP_last']
            personalProfiles[index][alias+"_"+'ZS'] = check_dates_dict['ZS']
          }
        }))
    //   console.log('personal profiles filter kurzy:',personalProfiles)

    }

    console.log('number of persons in the database5:',personalProfiles.length)
    //add filter datumy
    if (osoby_datumy.length>0){
      // list of courses selected by user
      personalProfiles.map((person,index)=>{
        console.log('[functions.js] entering add filter datumy section:',person)
        // var person_tmp = JSON.parse(JSON.stringify(person))
        var person_tmp = Object.create(null)
        for (const key in person){
          person_tmp[key] = person[key]
        }
        // console.log('person bbbbefore:',person_tmp,JSON.stringify(person),JSON.parse(JSON.stringify(person)))
        aliases.map(alias=>{
          // console.log('typeof:',person[alias+'_'+'when_AOP'],typeof(person[alias+'_'+'when_AOP']))
          let remove_indices_os = []
          let remove_indices_aop = []
        //   console.log('check dates for alias:',alias, ' and person:',person)
          if ([alias+'_'+'ZS'] in person){
            const validity_OS = person[alias+'_'+'when_OS'].split(',').map(element => element.trim())
            const validity_AOP = person[alias+'_'+'when_AOP'].split(',').map(element => element.trim())
            // console.log('validity for person:',person,': ',validity_OS,validity_AOP)
            validity_OS[0]!=='' && validity_OS.map((deadline,i)=>{
              const within_deadline = withinIntervalCheckDeadline(deadline,osoby_datumy,conditions_dicts)
            //   console.log('OS deadline check:',deadline,within_deadline)
              if (within_deadline === 'no'){
                remove_indices_os.push(i)
              }
            })
            // console.log('some prints:',person_tmp[alias+'_'+'when_OS'].split(','),person_tmp[alias+'_'+'when_OS'].split(',').map(element => element.trim()).filter((_,i)=>!remove_indices_os.includes(i)).join(', '))
            person_tmp[alias+'_'+'when_OS'] = person_tmp[alias+'_'+'when_OS'].split(',').map(element => element.trim()).filter((_,i)=>!remove_indices_os.includes(i)).join(', ')
            person_tmp[alias+'_'+'when_OS_last'] = person_tmp[alias+'_'+'when_OS_last'].split(',').map(element => element.trim()).filter((_,i)=>!remove_indices_os.includes(i)).join(', ')
            person_tmp[alias+'_'+'which_OS'] = person_tmp[alias+'_'+'which_OS'].split(',').map(element => element.trim()).filter((_,i)=>!remove_indices_os.includes(i)).join(', ')
            
            validity_AOP[0]!=='' && validity_AOP.map((deadline,j)=>{
              const within_deadline = withinIntervalCheckDeadline(deadline,osoby_datumy,conditions_dicts)
            //   console.log('OS deadline check:',deadline,within_deadline)
              if (within_deadline === 'no'){
                remove_indices_aop.push(j)
              }
            })
            // console.log('person before:',person_tmp)
            person_tmp[alias+'_'+'when_AOP'] = person_tmp[alias+'_'+'when_AOP'].split(',').map(element => element.trim()).filter((_,i)=>!remove_indices_aop.includes(i)).join(', ')
            person_tmp[alias+'_'+'when_AOP_last'] = person_tmp[alias+'_'+'when_AOP_last'].split(',').map(element => element.trim()).filter((_,i)=>!remove_indices_aop.includes(i)).join(', ')
            person_tmp[alias+'_'+'which_AOP'] = person_tmp[alias+'_'+'which_AOP'].split(',').map(element => element.trim()).filter((_,i)=>!remove_indices_aop.includes(i)).join(', ')
            // console.log('person after:',person_tmp)
            if (person_tmp[alias+'_'+'which_AOP']==="" && person_tmp[alias+'_'+'which_OS'] === ""){
              person_tmp[alias+'_'+'ZS'] = ""
            }
          }
        })
        personalProfiles[index] = JSON.parse(JSON.stringify(person_tmp))
        // console.log('persona:',person, 'dict:',personalProfiles[index])
      })

      //remove all such persons that would have empty row in the table - do not fall into selected timeframe
      let remove_person_indices = []
      personalProfiles.map((person,index)=>{
        let remove_person = true
        // Promise.all(aliases.map(alias=>{
        aliases.map(alias=>{
        //   console.log('person,alias,ZS:',person,alias,[person[alias+'_ZS']])
          if (person[alias+'_ZS']!=="") remove_person = false
        // }))
        })
        if (remove_person === true){
        //   console.log('removing person with index:',index)
          remove_person_indices.push(index)
        }
      })
      personalProfiles = personalProfiles.filter((_,index)=>!remove_person_indices.includes(index))

      const courses = osoby_courses
    //   console.log('TO DO: filter courses base on dates')
    }

    console.log('number of persons in the database6:',personalProfiles.length)
    // console.log('personal profiles:',personalProfiles,osoby_courses.map(item=>{return {'meno': item}}).concat([1,2,3]))
    // return personalProfiles.sort((a, b) => {if (a.priezvisko !== b.priezvisko) {return a.priezvisko > b.priezvisko ? 1:-1} 
    // else {if (a.meno !== b.meno)  {return a.meno > b.meno ? 1:-1} else {return a["dátum narodenia"]> b["dátum narodenia"]? 1:-1}}  })
    return personalProfiles.sort((a, b) => {if (a.priezvisko !== b.priezvisko) {return a.priezvisko.localeCompare(b.priezvisko,'sk')>0 ? 1:-1} 
    else {if (a.meno !== b.meno)  {return a.meno.localeCompare(b.meno,'sk')>0 ? 1:-1} else {return a["dátum narodenia"]> b["dátum narodenia"]? 1:-1}}  })
  }


function checkDates(person,row,alias,dates,companies,allCourses,conditions_dicts){
    // let what = []
    let which_OS = []
    let which_AOP = []
    let when_OS = []
    let when_OS_last = []
    let when_AOP = []
    let when_AOP_last = []
    // console.log('checking row:',row,' for dates: ',dates)
    console.log('person: ',person)

    //determine what is period of OS and/or AOP either from company settings or customized periods from specialized file
    const zamestnavatel = person['zamestnávateľ']
    const OShappens = (allCourses.filter(item=>item.course_alias===alias)[0].course_OS===true)?true:false
    const AOPhappens = (allCourses.filter(item=>item.course_alias===alias)[0].course_AOP===true)?true:false
    const course_nazov = allCourses.filter(item=>item.course_alias===alias)[0].course_nazov
    let periodOS = 24
    let periodAOP = 60
    let custom_periodsOS = null
    let custom_periodsAOP = null
    // console.log('zamestnavatel:',zamestnavatel)
    // determine what is period of OS and/or AOP from company settings
    if (zamestnavatel){
    //   console.log('companies: ',companies)
      const company = companies.filter(item=>item.company_nazov===zamestnavatel)[0]
      custom_periodsOS = JSON.parse(company.company_vlastna_perioda_os)
      // custom_periodsAOP = JSON.parse(companies.filter(item=>item.company_nazov===zamestnavatel)[0].company_vlastna_perioda_aop)
      custom_periodsAOP = JSON.parse(company.company_vlastna_perioda_aop)
      console.log('zamestnavatel, company:',zamestnavatel,company,alias,custom_periodsOS,alias in custom_periodsOS)
      // if (custom_periodsOS.includes(course_nazov)){
      //   periodOS = 1
      // }
      // if (custom_periodsAOP.includes(course_nazov)){
      //   periodAOP = 4
      // }
      if (alias in custom_periodsOS){
        periodOS = custom_periodsOS[alias]
      }
      if (alias in custom_periodsAOP){
        periodAOP = custom_periodsAOP[alias]
      }
    }

    // determine what is period of OS and/or AOP from specialized file
    if ((!zamestnavatel && alias in custom_os_aop)){
    //   console.log('change:',custom_os_aop[alias].os,custom_os_aop[alias].aop)
      if (custom_os_aop[alias].os) periodOS = custom_os_aop[alias].os
      if (custom_os_aop[alias].aop) periodAOP = custom_os_aop[alias].aop
    }

    let shift = 0 // if 0 - no license case, if 1 then evcisla
    let evcisla = (row[alias+"_zevcislo"])?row[alias+"_zevcislo"].split(';;'):[]
    let vydania = (row[alias+"_zvydanie"])?row[alias+"_zvydanie"].split(';;'):[]
    if (evcisla.length===0){
      evcisla = [course_nazov]
    }else{
      shift = 1
    }

    console.log('predOS, predAOP:',periodOS,periodAOP)
    
    // console.log('zevcislo:',evcisla)
    // console.log('periodOS, periodAOP, OShappens, AOPhappens:',periodOS, periodAOP, OShappens, AOPhappens)

    evcisla.map((evcislo,index)=>{
      //OS or AOP, in both cases, check if there are some predOS/predAOP for a given person, 
      // if yes, select the last date from these to match the deadline, otherwise, check if the deadline given in dates 
      // can be matched by ZK date (no preukaz) or date of vydanie (preukaz with ev.cislo).
      if (OShappens===true){
        let check = JSON.parse(row[alias+"_predOS"])[index+shift]
        if (AOPhappens===true){
          check = check.concat(JSON.parse(row[alias+"_predAOP"])[index+shift])
        }
        // console.log('row-check:',check)
        if (check.length===0){
          const check_ZS = row[alias+"_zskuska"]
          // console.log('row-check ZS:',check_ZS)
          const baseDate = (shift === 1 && vydania[index])?vydania[index]:check_ZS
          const WHEN = withinInterval(baseDate,periodOS,dates,conditions_dicts)
          // console.log('WHEN:',WHEN)
          if (WHEN!=='no'){
            // console.log('satisfied for person, course, what, which, when:',person.meno, person.priezvisko, alias, 'OŠ',evcislo,WHEN)
            // what.push('OŠ')
            which_OS.push(evcislo)
            when_OS.push(WHEN)
            when_OS_last.push(baseDate)
          }
        }else{
          const last_OS = check.sort(stringToTime)[0]
        //   console.log('row-check OS last:',last_OS)
          const WHEN = withinInterval(last_OS,periodOS,dates,conditions_dicts)
          // console.log('WHEN:',WHEN)
          if (WHEN!=='no'){
            // console.log('satisfied for person, course, what, which, when, whom:',person.meno, person.priezvisko, alias, 'OŠ',evcislo,WHEN,alias)
            // what.push('OŠ')
            which_OS.push(evcislo)
            when_OS.push(WHEN)
            when_OS_last.push(last_OS)
          }
        }
      }

      if (AOPhappens===true){
        const check = JSON.parse(row[alias+"_predAOP"])[index+shift]
        // console.log('row-check:',check)
        if (check.length===0){
          const check_ZS = row[alias+"_zskuska"]
        //   console.log('row-check ZS:',check_ZS)
          const baseDate = (shift === 1 && vydania[index])?vydania[index]:check_ZS
          const WHEN = withinInterval(baseDate,periodAOP,dates,conditions_dicts)
          // console.log('WHEN:',WHEN)
          if (WHEN!=='no'){
            // console.log('satisfied for person, course, what, which, when:',person.meno, person.priezvisko, alias, 'AOP',evcislo,WHEN)
            // what.push('AOP')
            which_AOP.push(evcislo)
            when_AOP.push(WHEN)
            when_AOP_last.push(baseDate)
          }
        }else{
          const last_AOP = check.sort(stringToTime)[0]
        //   console.log('row-check AOP last:',last_AOP)
          const WHEN = withinInterval(last_AOP,periodAOP,dates,conditions_dicts)
          // console.log('WHEN:',WHEN)
          if (WHEN!=='no'){
            // console.log('satisfied for person, course, what, which, when:',person.meno, person.priezvisko, alias, 'AOP',evcislo,WHEN)
            // what.push('AOP')
            which_AOP.push(evcislo)
            when_AOP.push(WHEN)
            when_AOP_last.push(last_AOP)
          }
        }
      }
    })
    let terminyDataAddOns_tmp = {}
    if (which_OS.length + which_AOP.length>0){
      // terminyDataAddOns_tmp[person.id] = {}
      terminyDataAddOns_tmp['ZS'] = row[alias+"_zskuska"]
      // terminyDataAddOns_tmp['what'] = what
      terminyDataAddOns_tmp['which_OS'] = which_OS
      terminyDataAddOns_tmp['which_AOP'] = which_AOP
      terminyDataAddOns_tmp['when_OS_next'] = when_OS
      terminyDataAddOns_tmp['when_OS_last'] = when_OS_last
      terminyDataAddOns_tmp['when_AOP_next'] = when_AOP
      terminyDataAddOns_tmp['when_AOP_last'] = when_AOP_last
      // terminyDataAddOns_tmp['whom'] = alias
      // console.log('person:',person.meno, person.priezvisko)
      // console.log('which_OS,when_OS,when_OS_last,which_AOP,when_AOP,when_AOP_last:', which_OS, when_OS, when_OS_last,which_AOP,when_AOP,when_AOP_last)
    }
    return (which_OS.length + which_AOP.length>0)?[true,terminyDataAddOns_tmp]:[false,null]
  }

  function checkDatesAopOsAutoSuggestion(persons_company,row,course,dates,evcislo2check,typ){

    // in this function, we determine whether an evcislo lays within a deadline 
    // used for autosuggesting of aop/os courses in modul Kurzy
    // param persons_company: person's company dictonary
    // param row: row from a course table of a given person
    // param course: actual course from allCourses array
    // param dates: entries for date filtering (from selection menu for dates)
    // param conditions_dicts: dict of conditions for date filtering
    // param evcislo2check: license evcislo to infer a deadline for, either evcislo or course name
    // param typ: 'aop' or 'os'
    // returns WHEN: when the next skolenie within the selected time span should happen. If 'no', skolenie shhould not be within the selected time period

    let conditions_dicts = setConditionDict()
    // console.log('checking row:',row,' for dates: ',dates,' company:',persons_company)

    //determine what is period of OS and/or AOP either from company settings or customized periods from specialized file
    const alias = course.course_alias
    const zamestnavatel = (persons_company)?persons_company.company_nazov:null
    // console.log('zamestnavatel auto:',zamestnavatel,persons_company)
    const OShappens = (course.course_OS===true)?true:false
    const AOPhappens = (course.course_AOP===true)?true:false
    const course_nazov = course.course_nazov
    let WHEN = null

    let periodOS = 24
    let periodAOP = 60
    let custom_periodsOS = null
    let custom_periodsAOP = null
    // determine what is period of OS and/or AOP from company settings
    if (zamestnavatel){
      const company = persons_company
      custom_periodsOS = JSON.parse(company.company_vlastna_perioda_os)
      custom_periodsAOP = JSON.parse(company.company_vlastna_perioda_aop)
      // console.log('zamestnavatel2, company2:',zamestnavatel,company,alias,custom_periodsOS,alias in custom_periodsOS)
      // if (custom_periodsOS.includes(course_nazov)){
      //   periodOS = 1
      // }
      // if (custom_periodsAOP.includes(course_nazov)){
      //   periodAOP = 4
      // }
      if (alias in custom_periodsOS){
        periodOS = custom_periodsOS[alias]
      }
      if (alias in custom_periodsAOP){
        periodAOP = custom_periodsAOP[alias]
      }
    }

    // determine what is period of OS and/or AOP from specialized file
    if ((!zamestnavatel && alias in custom_os_aop)){
      if (custom_os_aop[alias].os) periodOS = custom_os_aop[alias].os
      if (custom_os_aop[alias].aop) periodAOP = custom_os_aop[alias].aop
    }

    // console.log('control: periodOS auto:',periodOS)


    let shift = 0 // if 0 - no license case, if 1 then evcisla
    let evcisla = (row[alias+"_zevcislo"])?row[alias+"_zevcislo"].split(';;'):[]
    let vydania = (row[alias+"_zvydanie"])?row[alias+"_zvydanie"].split(';;'):[]
    if (evcisla.length===0){
      evcisla = [course_nazov]
    }else{
      shift = 1
    }
    // console.log('line,evcisla vs evcislo2check:',row,evcisla,evcislo2check)
    evcisla.map((evcislo,index)=>{
      //OS or AOP, in both cases, check if there are some predOS/predAOP for a given person, 
      // if yes, select the last date from these to match the deadline, otherwise, check if the deadline given in dates 
      // can be matched by ZK date (no preukaz) or date of vydanie (preukaz with ev.cislo).
      
      //in this function, we are checking only a single evcislo

      if (evcislo===evcislo2check){

        if (OShappens===true && typ === 'os'){
          let check = JSON.parse(row[alias+"_predOS"])[index+shift]
          if (AOPhappens===true){
            check = check.concat(JSON.parse(row[alias+"_predAOP"])[index+shift])
          }
          // console.log('row-check:',check)
          if (check.length===0){
            const check_ZS = row[alias+"_zskuska"]
            // console.log('row-check ZS:',check_ZS)
            const baseDate = (shift === 1 && vydania[index])?vydania[index]:check_ZS
            WHEN = withinInterval2(baseDate,periodOS,dates,conditions_dicts)
            // console.log('WHEN:',WHEN)
          }else{
            const last_OS = check.sort(stringToTime)[0]
          //   console.log('row-check OS last:',last_OS)
            WHEN = withinInterval2(last_OS,periodOS,dates,conditions_dicts)
            // console.log('WHEN:',WHEN)
          }
        }

        if (AOPhappens===true && typ === 'aop'){
          const check = JSON.parse(row[alias+"_predAOP"])[index+shift]
          // console.log('row-check:',check)
          if (check.length===0){
            const check_ZS = row[alias+"_zskuska"]
          //   console.log('row-check ZS:',check_ZS)
            const baseDate = (shift === 1 && vydania[index])?vydania[index]:check_ZS
            WHEN = withinInterval2(baseDate,periodAOP,dates,conditions_dicts)
            // console.log('WHEN:',WHEN)

          }else{
            const last_AOP = check.sort(stringToTime)[0]
            WHEN = withinInterval2(last_AOP,periodAOP,dates,conditions_dicts)
            // console.log('WHEN:',WHEN)
          }
        }
      }
    })
    return WHEN
  }


function withinIntervalCheckDeadline(deadln,conditions,conditions_dicts){
    // future_pos_diff(date,ref_date) 1 - date more in future compared to ref_date, -1 if opposite
    let when = 'no'
    const deadline = deadln

    conditions.map(condition=>{
    //   console.log('lower, deadline, higher:',conditions_dicts[condition].lower,deadline,conditions_dicts[condition].upper)
    //   console.log('returning2 boundary checks lower,higher:',future_pos_diff(deadline,conditions_dicts[condition].lower),future_pos_diff(deadline,conditions_dicts[condition].upper))
      if (future_pos_diff(deadline,conditions_dicts[condition].lower)>=0 && future_pos_diff(deadline,conditions_dicts[condition].upper)<=0){
        // console.log('passed!')
        when = deadline
      }
    })
    return when
  }

function withinInterval(base,period,conditions,conditions_dicts){
    // future_pos_diff(date,ref_date) 1 - date more in future compared to ref_date, -1 if opposite
    console.log('[withinInterval]: base, period ',base,period,Math.floor(period/12),period%12)
    let when = 'no'
    const updateYearyBy = Math.floor(period/12)
    const updateMonthBy = period%12
    let yearUpdated = Number(base.split('.')[2]) + updateYearyBy
    let monthUpdated = Number(base.split('.')[1]) + updateMonthBy
    if (monthUpdated>12){
      monthUpdated -= 12
      yearUpdated += 1
    }
    const deadline = (monthUpdated<10)?[base.split('.')[0],'0'+monthUpdated,yearUpdated].join('.'):[base.split('.')[0],monthUpdated,yearUpdated].join('.')

    conditions.map(condition=>{
    //   console.log('lower, deadline, higher:',conditions_dicts[condition].lower,deadline,conditions_dicts[condition].upper)
    //   console.log('returning boundary checks lower,higher:',future_pos_diff(deadline,conditions_dicts[condition].lower),future_pos_diff(deadline,conditions_dicts[condition].upper))
      if (future_pos_diff(deadline,conditions_dicts[condition].lower)>=0 && future_pos_diff(deadline,conditions_dicts[condition].upper)<=0){
        // console.log('passed!')
        when = deadline
      }
    })
    return when
  }

  function withinInterval2(base,period,conditions,conditions_dicts){
    // here opposed to previous, we always return deadline as a second argument
    // future_pos_diff(date,ref_date) 1 - date more in future compared to ref_date, -1 if opposite
    let when = 'no'
    // let yearUpdated = Number(base.split('.')[2]) + Number(period)
    // const deadline = [base.split('.')[0],base.split('.')[1],yearUpdated].join('.')
    // console.log('[withinInterval2]: base, period ',base,period,Math.floor(period/12),period%12)
    const updateYearyBy = Math.floor(period/12)
    const updateMonthBy = period%12
    let yearUpdated = Number(base.split('.')[2]) + updateYearyBy
    let monthUpdated = Number(base.split('.')[1]) + updateMonthBy
    if (monthUpdated>12){
      monthUpdated -= 12
      yearUpdated += 1
    }
    const deadline = (monthUpdated<10)?[base.split('.')[0],'0'+monthUpdated,yearUpdated].join('.'):[base.split('.')[0],monthUpdated,yearUpdated].join('.')


    conditions.map(condition=>{
    //   console.log('lower, deadline, higher:',conditions_dicts[condition].lower,deadline,conditions_dicts[condition].upper)
    //   console.log('returning boundary checks lower,higher:',future_pos_diff(deadline,conditions_dicts[condition].lower),future_pos_diff(deadline,conditions_dicts[condition].upper))
      if (future_pos_diff(deadline,conditions_dicts[condition].lower)>=0 && future_pos_diff(deadline,conditions_dicts[condition].upper)<=0){
        // console.log('passed!')
        when = deadline
      }
    })
    return [when, deadline]
  }

function stringToTime(a,b){
    // console.log('a,b;',a,b)
    if (a && b){
    var [d11,m11,r11] = a.split(".")
    var [d22,m22,r22] = b.split(".")
    var d1 = Number(d11)
    var d2 = Number(d22)
    var m1 = Number(m11)
    var m2 = Number(m22)
    var r1 = Number(r11)
    var r2 = Number(r22)

    if (r1>r2) return -1//[a,b]
    if (r1<r2) return 1//[b,a]
    if (r1===r2) {
      if (m1>m2) return -1//[a,b]
      if (m1<m2) return 1//[b,a]
      if (m1===m2) {
        if (d1>d2) return -1//[a,b]
        if (d1<d2) return 1//[b,a]
        if (d1===d2) return -1//[a,b]
      }
    }
  }else return 1//[a,b]
  }

function future_pos_diff(date,date_ref = null){
    //returns 1 if date is in future and -1 if in the past

    let day_now, month_now, year_now

    if (!isDate(date)) {
    //   console.log('wrong format: ',date)
      return "wrong format"
    }
    if (date_ref){
      let date_now_split  = date_ref.split('.')
      day_now = Number(date_now_split[0])
      month_now = Number(date_now_split[1])
      year_now = Number(date_now_split[2])
    }
    else{
      let today = new Date()
      day_now = today.getDate()
      month_now = today.getMonth()+1
      year_now = today.getFullYear()
    }

    let date_split  =date.split('.')
    let day_check = Number(date_split[0])
    let month_check = Number(date_split[1])
    let year_check = Number(date_split[2])
    // console.log('now:',day_now,month_now,year_now)
    // console.log('check:',day_check,month_check,year_check)
    // console.log('check diff:',(year_check - year_now)*365 ,(month_check - month_now)*30 ,(day_check - day_now))
    let diff = (year_check - year_now)*365 + (month_check - month_now)*30 + (day_check - day_now)
    return diff
  } 

function isDate(date) {
    return /^(\d{2})(\/|-|\.)(\d{2})(\/|-|\.)(\d{4})$/.test(date);
  }

function compose_header_personal_profiles(osoby_courses,allCourses){
	// create header for personal profile table
	const osoby_courses_aliases = osoby_courses.map(item=>getCourseAliasFromName(item,allCourses))
	let append = []
	// console.log('aliases compose header:',osoby_courses_aliases)
	osoby_courses_aliases.map((alias,index)=>{
	  if (allCourses.filter(item2=>item2.course_alias===alias)[0].course_OS===true && (allCourses.filter(item2=>item2.course_alias===alias)[0].course_AOP===true)){
		let toAppend = [{[alias+'_'+"ZS"]: "ZŠ ("+osoby_courses[index].slice(0,20)+"...)" },
		{[alias+'_'+"when_OS_last"]: "posl. OŠ alebo ZŠ ("+osoby_courses[index].slice(0,20)+"...)" },
		{[alias+'_'+"when_OS"]: "nasl. OŠ ("+osoby_courses[index].slice(0,20)+"...)" },
		{[alias+'_'+"which_OS"]: "ev. č. OŠ ("+osoby_courses[index].slice(0,20)+"...)" },
		{[alias+'_'+"when_AOP_last"]: "posl. AOP alebo ZŠ ("+osoby_courses[index].slice(0,20)+"...)" },
		{[alias+'_'+"when_AOP"]: "nasl. AOP ("+osoby_courses[index].slice(0,20)+"...)" },
		{[alias+'_'+"which_AOP"]: "ev. č. AOP ("+osoby_courses[index].slice(0,20)+"...)" },
		]
		append = append.concat(toAppend)
	  }else if (allCourses.filter(item2=>item2.course_alias===alias)[0].course_OS===true){
		let toAppend = [{[alias+'_'+"ZS"]: "ZŠ ("+osoby_courses[index].slice(0,20)+"...)" },
		{[alias+'_'+"when_OS_last"]: "posl. OŠ alebo ZŠ ("+osoby_courses[index].slice(0,20)+"...)" },
		{[alias+'_'+"when_OS"]: "nasl. OŠ ("+osoby_courses[index].slice(0,20)+"...)" },
		{[alias+'_'+"which_OS"]: "ev. č. OŠ ("+osoby_courses[index].slice(0,20)+"...)" },
		]
		append = append.concat(toAppend)
	  }else{
		let toAppend = [{[alias+'_'+"ZS"]: "ZŠ ("+osoby_courses[index].slice(0,20)+"...)" },
		{[alias+'_'+"when_AOP_last"]: "posl. AOP alebo ZŠ ("+osoby_courses[index].slice(0,20)+"...)" },
		{[alias+'_'+"when_AOP"]: "nasl. AOP ("+osoby_courses[index].slice(0,20)+"...)" },
		{[alias+'_'+"which_AOP"]: "ev. č. AOP ("+osoby_courses[index].slice(0,20)+"...)" },
		]
		append = append.concat(toAppend)
	  }
	  
	  })
	// console.log('append:',append)
	const headers = [{'priezvisko':'Priezvisko'}, {'meno': 'Meno'}, {'titul':'Titul'}, {'dátum narodenia':  'Dátum narodenia'}, {'zamestnávateľ': 'Zamestnávateľ'},{'pobocka': 'Pobočka'}, {'profesia': 'Profesia'}].concat(...append)
	return headers
}

function setConditionDict(){
  let days={
    1: 31,
    2: 29,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
  }
  let conditions_dicts = {
    'zmeškané do dnes': {'lower':'00.00.0000','upper':'23.01.2022'},
    'aktuálny mesiac': {'lower':'00.00.0000','upper':'23.01.2022'},
    'aktuálny+nasledujúci mesiac': {'lower':'00.00.0000','upper':'23.01.2022'},
    'aktuálny+nasledujúce 2 mesiace': {'lower':'00.00.0000','upper':'23.01.2022'},
    'tento rok': {'lower':'00.00.0000','upper':'23.01.2022'},
    'neohraničené': {'lower':'00.00.0000','upper':'31.12.9999'},
  }
  let today = new Date()
  let day_now = today.getDate()
  let month_now = today.getMonth()+1
  let year_now = today.getFullYear()
  const month_nowStr = (month_now>9)?String(month_now):'0'+String(month_now)
  const month_plus1Str = (month_now+1>9)?String(month_now+1):'0'+String(month_now+1)
  const month_plus2Str = (month_now+2>9)?String(month_now+2):'0'+String(month_now+2)
  const day_nowStr = (day_now>9)?String(day_now):'0'+String(day_now)
  const todayStr = [day_nowStr,month_nowStr,year_now].join('.')
  const startOfActualMonth = ['01',month_nowStr,year_now].join('.')
  const endOfActualMonth = [days[month_now],month_nowStr,year_now].join('.')
  
  const endOfPlus1Month = (month_plus1Str === '13')?[days[1],'01',year_now+1].join('.'):[days[month_now+1],month_plus1Str,year_now].join('.')
  const endOfPlus2Month = (month_plus2Str === '13')?[days[1],'01',year_now+1].join('.'):(
    (month_plus2Str === '14')?[days[2],'02',year_now+1].join('.'):[days[month_now+2],month_plus2Str,year_now].join('.')
  )
  
  const startOfActualYear = ['01','01',year_now].join('.')
  const endOfActualYear = ['31','12',year_now].join('.')
  // console.log('times:',todayStr,month_nowStr,month_plus1Str,month_plus2Str)

  conditions_dicts['zmeškané do dnes'].lower = '00.00.0000'
  conditions_dicts['zmeškané do dnes'].upper = todayStr
  conditions_dicts['aktuálny mesiac'].lower = startOfActualMonth
  conditions_dicts['aktuálny mesiac'].upper = endOfActualMonth
  conditions_dicts['aktuálny+nasledujúci mesiac'].lower = startOfActualMonth
  conditions_dicts['aktuálny+nasledujúci mesiac'].upper = endOfPlus1Month
  conditions_dicts['aktuálny+nasledujúce 2 mesiace'].lower = startOfActualMonth
  conditions_dicts['aktuálny+nasledujúce 2 mesiace'].upper = endOfPlus2Month
  conditions_dicts['tento rok'].lower = startOfActualYear
  conditions_dicts['tento rok'].upper = endOfActualYear
  // console.log('updated conditionDict:',conditions_dicts)
  return conditions_dicts
}

function getCourseAliasFromName(name,allCourses){
    return allCourses.filter(item=>item.course_nazov===name)[0].course_alias
  }

  export{ arrangePersons, isDate, compose_header_personal_profiles,setConditionDict,checkDatesAopOsAutoSuggestion,future_pos_diff }