import React, { Component, useEffect, useState } from "react";
import DataService from "../services/tutorial.service";
import { Redirect } from 'react-router';
import Table from 'react-bootstrap/Table'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from "react-router-dom";

import "./../components/styles.css";
import SweetAlert from 'react-bootstrap-sweetalert';

import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FaGem, FaHeart, FaAlgolia, FaCheck, FaSort, FaCloudDownloadAlt, FaFilePdf, FaRegFilePdf, FaFileExcel} from "react-icons/fa";
import {TiArrowSortedDown,TiArrowSortedUp,TiArrowUnsorted} from "react-icons/ti"


import { Container } from "react-bootstrap";

import Font, {Text} from 'react-font';
import MultiSelect from "react-multi-select-component";

import jsPDF from "jspdf";
import "jspdf-autotable";

import { BsBorderWidth } from "react-icons/bs";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { MDBDataTable } from 'mdbreact';
import * as XLSX from 'xlsx'
// import styles1 from '@fortawesome/fontawesome-free/css/all.min.css';
// import styles2 from 'bootstrap-css-only/css/bootstrap.min.css';
// import styles3 from 'mdbreact/dist/css/mdb.css';
import { isDate,future_pos_diff } from "../controls/functions";

pdfMake.vfs = pdfFonts.pdfMake.vfs;


const Table_template = (props) => {

	const data_base = get_data()
	// console.log('base data: ',data_base)

	// console.log('data:',data)
	const  headers = get_headers()
	const [showHeader,setShowHeader] = useState(false)

	const [showMBtable,setShowMBtable] = useState(false)

	console.log('base data: ',data_base)
	const [data,setData] = useState(()=>{
		// console.log('setting data to:',data_base)
		return data_base	
	})
	// const [data,setData] = useState([])
	// setData(data_base)
	console.log('data:',data)
	const [update,setUpdate] = useState([...[1],...new Array(headers.length-1).fill(0)])

    function get_headers(){
		const headers = props.headers.map((header,index)=>Object.values(header)[0])
		// console.log('headers:',headers)
        return headers
    }

	function get_data(){
		let data1 = []
		props.items && props.items.map((item,index_item)=>{
			
			data1.push([index_item+1])
			props.headers.map((header,index)=>(data1[index_item].push(item[Object.keys(header)[0]])))
		})
		console.log('data1:',data1)
        return data1
    }



	const exportPDF = (headers, data) => {
		console.log('data: in export pdf:',data,data[0])
		
		var headers_ext = [['#'].concat(headers)]
		console.log('headers2',headers,headers_ext,headers.includes('Typ školenia'))

		var data_pdf = []
		data_pdf = headers_ext.concat(data)
		console.log('data_pdf:',data_pdf)
		if (props.type==='Technici'){
			let data_pdf_updated = JSON.parse(JSON.stringify(data_pdf))
			var docDefinition = {
				pageSize:'A4',
				pageMargins: [ 40, 60, 40, 60 ],
				pageOrientation: 'portrait',
				content: [
				
				(showHeader===true)?{text: `Filtre: ${props.type}`, style: 'header'}:null,
				
				{
					layout: 'lightHorizontalLines', // optional
					table: {
					// headers are automatically repeated if the table spans over multiple pages
					// you can declare how many rows should be treated as headers
					headerRows: 1,
					widths: ['auto',...Array(headers.length).fill('*')],//, '*', '*' ,'*'],
			
					body: data_pdf_updated
					}
				}
				]
			};
		}
		if (props.type==='Firmy'){
			let data_pdf_updated = JSON.parse(JSON.stringify(data_pdf))
			for (let i=1;i<data_pdf_updated.length;i++){
				console.log('print: ',data_pdf_updated[i][13],JSON.parse(data_pdf_updated[i][13]))
				data_pdf_updated[i][13] = JSON.parse(data_pdf_updated[i][13]).join(", ")
				// forEach(item=>item[13].text = JSON.parse(item[13].text))
			}
			var docDefinition = {
				pageSize:'A4',
				pageMargins: [ 15, 60, 40, 60 ],
				pageOrientation: 'landscape',
				
				defaultStyle: {
					fontSize: 4.5,
					bold: false,
					alignment: "left"
				  },

				content: [
				
				(showHeader===true)?{text: `Filtre: ${props.type}`, style: 'header'}:null,
				(showHeader===true)?{text: ` `, style: 'header'}:null,
				(showHeader===true)?{text: `  Vykonáva sa: ${props.filtre['Vykonáva sa'].join(', ')}`}:null,
				(showHeader===true)?{text: `  Význam: ${props.filtre['Významy'].join(', ')}`}:null,
				(showHeader===true)?{text: `  Pridelenie 1: ${props.filtre['Pridelenie 1'].join(', ')}`}:null,
				(showHeader===true)?{text: `  Pridelenie 2: ${props.filtre['Pridelenie 2'].join(', ')}`}:null,
				
				{
					layout: 'lightHorizontalLines', // optional
					table: {
					// headers are automatically repeated if the table spans over multiple pages
					// you can declare how many rows should be treated as headers
					headerRows: 1,
					
					// widths: ['auto',...Array(headers.length).fill('*')],//, '*', '*' ,'*'],
					// widths: ['auto',...Array(headers.length).fill('auto')],//, '*', '*' ,'*'],
					body: data_pdf_updated
					}
				}
				]
			};
		}
		if (props.type==='Osoby'){
			let data_pdf_updated = JSON.parse(JSON.stringify(data_pdf))
			var docDefinition = {
				pageSize:'A4',
				pageMargins: [ 40, 60, 40, 60 ],
				// pageOrientation: (headers.includes('Typ školenia'))?'landscape':'portrait',
				pageOrientation: (headers.length>7)?'landscape':'portrait',
				content: [

					(showHeader===true)?{text: `Filtre: ${props.type}`, style: 'header'}:null,
					(showHeader===true)?{text: ` `, style: 'header'}:null,
					(showHeader===true)?{text: `  Firmy: ${props.filtre['Firmy'].join(', ')}`}:null,
					(showHeader===true)?{text: `  Pridelenie 1: ${props.filtre['Pridelenie 1'].join(', ')}`}:null,
					(showHeader===true)?{text: `  Pridelenie 2: ${props.filtre['Pridelenie 2'].join(', ')}`}:null,
					(showHeader===true)?{text: `  Kurzy: ${props.filtre['Kurzy'].join(', ')}`}:null,
					(showHeader===true)?{text: `  V období: ${props.filtre['V období'].join(', ')}`}:null,
				{
					layout: 'lightHorizontalLines', // optional
					table: {
					// headers are automatically repeated if the table spans over multiple pages
					// you can declare how many rows should be treated as headers
					headerRows: 1,
					
					// widths: Array(headers.length + 1).fill('*'),//, '*', '*' ,'*'],
					// widths: (headers.includes('Typ školenia'))?null:[...Array(headers.length).fill('*'),'auto'],
					// widths: (headers.includes('Typ školenia'))?null:['auto',...Array(headers.length).fill('*')],
			
					body: data_pdf_updated
					}
				}
				]
			};
		}

		pdfMake.tableLayouts = {
			exampleLayout: {
			  hLineWidth: function (i, node) {
				if (i === 0 || i === node.table.body.length) {
				  return 0;
				}
				return (i === node.table.headerRows) ? 2 : 1;
			  },
			  vLineWidth: function (i) {
				return 0;
			  },
			  hLineColor: function (i, node) {
				return i === node.table.headerRows ? 'black' : '#aaa';
			  },
			  paddingLeft: function (i) {
				return i === 0 ? 0 : 8;
			  },
			  paddingRight: function (i, node) {
				return (i === node.table.widths.length - 1) ? 0 : 8;
			  }
			}
		  };
		  
		  // download the PDF
		  pdfMake.createPdf(docDefinition).download();
		}

	const mouseClickEvents = ['mousedown', 'click', 'mouseup'];
	function simulateMouseClick(element){
		mouseClickEvents.forEach(mouseEventType =>
		element.dispatchEvent(
			new MouseEvent(mouseEventType, {
				view: window,
				bubbles: true,
				cancelable: true,
				buttons: 1
			})
		)
		);
	}
		
	console.log('props: ',props)
	
	// data = JSON.parse(JSON.stringify(data_base))
	
	
	console.log('headers:',headers)
	var headers_dict = [{
		label: '#',
		field: '#',
		sort: 'asc',
		// width: 100
	}]
	headers.map(header=>{
		headers_dict.push({
			label: header,
			field: header,
			sort: 'asc',
			// width: 250
			
		})
	})
	// console.log('headers_dict:',headers_dict)
	// console.log('data:',data)
	var data_dict = []
	data.forEach(item=>{
		var row = {}
		headers_dict.map((head,index)=>{
			// console.log('format:',head)
			if (head.field === "Dátum narodenia" || head.field.slice(0,2) === "ZŠ" || head.field.slice(0,5) === 'posl.' || head.field.slice(0,5) === "nasl.") {
				// if (item[index]!=="") row[head.field] = new Date(item[index])
				if (isDate(item[index])) {
					// console.log('index[item]:',item[index],new Date(item[index].split(".").reverse().join("-")))
					row[head.field] = new Date(item[index].split(".").reverse().join("-"))
				}
				else row[head.field] = item[index]
			}
			else{
				row[head.field] = item[index]
			}
		})
		data_dict.push(row)
	})
	console.log('data_dict:',data_dict)

	const data_MDB = {
		columns: headers_dict,
		rows: data_dict
	  };
	

	const exportExcel = () => {
		const worksheet = XLSX.utils.json_to_sheet(data_dict)
		const workbook = XLSX.utils.book_new()
		XLSX.utils.book_append_sheet(workbook,worksheet,"data")
		//Buffer once we have bulk data??
		let buff = XLSX.write(workbook,{bookType:"xlsx",type:"buffer"})
		//Binary string
		XLSX.write(workbook,{bookType:"xlsx",type:"binary"})
		//Download
		XLSX.writeFile(workbook,"filtre.xlsx")
	}

	function sortTable(n) {
		var update_tmp = new Array(update.length).fill(0)
		if (update[n]===0){
			update_tmp[n] = 1
		}else{
			update_tmp[n] = -1*update[n]
		}

		// console.log('sorting:',data)
		var datanew = data
		setData(datanew.sort((a,b)=>{
			if (!a[n+1]||!a[n+1]==="") return update_tmp[n]
			else if (!b[n+1]||!b[n+1]==="") return -update_tmp[n]
			else {
				// console.log('type of sorted field:',a[n+1])
				if (isDate(a[n+1])){
					// console.log('isDate')
					//isDate
					if (future_pos_diff(a[n+1],b[n+1])>1){
						return update_tmp[n]
					}else{
						return -update_tmp[n]
					}

				}else{
					
					// if (a[n+1]>b[n+1]) return update_tmp[n]
					// else return -update_tmp[n]
					if (a[n+1].localeCompare(b[n+1], "sk")>0) return update_tmp[n]
					else return -update_tmp[n]
				}
			}
		}))
		setUpdate(update_tmp)
		// console.log('after sorting:',data)
	  }

	useEffect(() => {
		setData(data_base)
		setUpdate([...[1],...new Array(headers.length-1).fill(0)])
		console.log('useEffect due to props.items upadate',data_base)
	}, [props.items])

	console.log('update array:',update)
	console.log('data:',data)
    
	return(
	//returns formatted table 
	<Col >
		<Row style={{marginTop:'1.0em',width:"100%",marginBottom:"4em"}}>
			<div style={{marginLeft: "2.5em",marginTop:"0.5em"}}>
				<label style={{color:"white", marginRight:"1em"}}>Pridať hlavičku s filtrami (pdf):</label>
				<BootstrapSwitchButton 
				checked={showHeader} 
				onstyle="primary" 
				offstyle="secondary" 
				onChange={()=>setShowHeader(!showHeader)}
				size='sm'/>
			</div>
			<div className="div-right">
				<button className="btn btn-outline-secondary" style={{borderWidth: "2px",marginTop: "0em",marginRight:"1em"}}>
					{/* <FaCloudDownloadAlt style={{fontSize: "30px", color: "white", cursor: "pointer"}} onClick ={()=>exportPDF(headers, data)}/> */}
					<FaFilePdf style={{fontSize: "30px", color: "white", cursor: "pointer"}} onClick ={()=>exportPDF(headers, data)}/>	
				</button>
				{/* {(showMBtable===false)? */}
					{/* <ReactHTMLTableToExcel 
							id="test-table-xls-button"
							// className="download-table-xls-button btn-outline-secondary"
							className="btn btn-outline-secondary"
							table="table-to-xls"
							filename="tablexls"
							sheet="tablexls"
							// buttonText="Stiahnuť Excel"	
							buttonText={<FaFileExcel style={{fontSize: "30px", color: "white", cursor: "pointer"}}/>}
							// onClick={setShowHeader(false)}
							/> */}
					{/* :*/}
					<button className="btn btn-outline-secondary" style={{borderWidth: "2px",marginTop: "0em",marginRight:"1em"}}>
						<FaFileExcel style={{fontSize: "30px", color: "white", cursor: "pointer"}} onClick ={()=>{exportExcel()}}/>	
					</button>
				{/* */}
			</div>
		</Row>
		<br/>
		<Row>
		{(showMBtable===false)?
			<div className="table-wrapper-scroll-y my-custom-scrollbar" >
			
				<Table id="table-to-xls" className="w-100 margin-auto tableFixHead" striped bordered hover style = {{backgroundColor: "white",marginBottom:'0em',height: "80vh"}}>

					<thead style={{fontStyle: "italic"}}>
					<tr>
						<th>#</th>
						{headers && headers.map((header,index)=>(
							<th>
								{header}  
								{(update[index]===0)
									?
										<TiArrowUnsorted key={update} onClick={()=>{sortTable(index)}}/>
									:((update[index]===1)?
										<TiArrowSortedDown key={update} onClick={()=>{sortTable(index)}}/>
									:
										<TiArrowSortedUp key={update} onClick={()=>{sortTable(index)}}/>
									)
								}
							</th>
						))}
					</tr>
					</thead>

					<tbody className = "">
						{data.map((item,index_item)=>(
						<tr>
							{item.map((input,index_input)=>(
							(typeof(input) === 'string' && input.includes('['))?(<td>{JSON.parse(input).map(it=>it+', ')}</td>):<td>{input}</td>
							// <td>{input}</td>
							))}
						</tr>
					))}
					</tbody>
				</Table>
				
			</div>
			:
			<div className="my-custom-scrollbar" style={{color:"white"}}>
				<MDBDataTable
					// id="table-to-xls"
					className="my-custom-scrollbar tableFixHead"
					style={{color:"white"}}
					// fixed
					className="my-custom-scrollbar"
					style={{backgroundColor:"white"}}
					noBottomColumns
					striped
					// bordered
					small
					data={data_MDB}

					paginationLabel={["Naspäť", "Ďalej"]}
					

					searchLabel={"Hľadaj"}
					noRecordsFoundLabel={"Neboli nájdené žiadne záznamy"}
					
					entriesOptions={[10,100,200,5000]}
					// entries={[5000]}
					displayEntries={true}
					entriesLabel={["Počet"," riadkov"]}
					infoLabel={["Showing", "toi", "of", "entries"]} 
					
					
					// scrollY
					// scrollX

					infoLabel={["Showing", "to", "of", "entries"]}
					// lengthMenu =  "Display _MENU_ records per page"
					// zeroRecords = "Nothing found - sorry"
						// "info": "Showing page _PAGE_ of _PAGES_",
						// "infoEmpty": "No records available",
						// "infoFiltered": "(filtered from _MAX_ total records)"

					
				/>
			</div>
		}
		</Row>
	</Col>
    )
    
}
export default Table_template;

