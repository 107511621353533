// import {checkDates} from './../components_terminy/terminyBase.component'
import DataService from "../services/tutorial.service";
import custom_os_aop from '../controls/custom_os_aop.variable';
const {arrangePersons,compose_header_personal_profiles,setConditionDict} = require('../controls/functions')


export default async function send_notifications(set_alert){
	const interval = 'month'
	let global_status = 'success'

	//so far, only interval === 'month' is implemented
	if (interval!== 'month'){
		console.log('routines for interval ',interval,' not implemented, exiting...')
		global_status = 'failed'
		return -1
	}
	
	console.log('processing notifications...')

	//get current month
	let today = new Date()
	let day_now = today.getDate()
	let month_now = today.getMonth()+1
	let year_now = today.getFullYear()
	console.log('current month',month_now,get_string_month(month_now))
	
	let last_update_year = null
	let last_update_month = null
	let niter = null
	let data_notifications = null
	try{
		data_notifications = await DataService.getNotificationHistory()
		data_notifications = data_notifications.data[0]//.sort((a,b)=>b.id - a.ib)
		console.log('data_notifications:',data_notifications)
		console.log('update_period:',data_notifications,data_notifications.at(-1))
		
		last_update_year = data_notifications.at(-1).update_period.split(" ")[1]
		last_update_month = get_number_month(data_notifications.at(-1).update_period.split(" ")[0])
		console.log('year and month of last update:',last_update_year,last_update_month)
		console.log('year and month today:',year_now,month_now)
		
		niter = (year_now - last_update_year)*12 + month_now - last_update_month
		if (niter===0) return "up_to_date"
		else
		{
			set_alert()
		}
	}catch(e){
		console.log('get_notification_history() failed with:',e)
		global_status = 'failed'
	}	
	// // get last update from the fetched table
	// last_update = get_last_update(data_notifications)



	let allCourses = null

	try{
		const response = await DataService.getAllCourses()
		console.log("received data",response.data);
		allCourses = response.data[0]
	}catch(e){
		console.log('allCourse fetch',e)
		global_status = 'failed'
	}

	let technicians = []
	let allTechnicians = null
	try{
		const response = await DataService.getAllTechnicians()
		allTechnicians = response.data[0]
		allTechnicians.map(item => technicians.push(item.technician_priezvisko + " " + item.technician_meno))
		console.log('received all technicians:',technicians)
	}catch(e){
		console.log('The technicians fetch failed!',e);
		global_status = 'failed'
	}
	
	let database_data = null
	try{
		let response = await DataService.getWholeDatabase()
		console.log("received full database data",response.data);
		database_data = response.data
	}catch(e){
		console.log('whole database fetch failed with:',e)
		global_status = 'failed'
	}


	let company_names = []
	let company_inactive=[]
	const companies = database_data.filter(function(table){return table['table_name']==='companies'})[0]['data']
	companies.map(company=>company_names.push(company.company_nazov))
	companies.map(company=>{if (company.active===false) {company_inactive.push(company.company_nazov)}})
	
	let course_names = []
	const courses = database_data.filter(function(table){return table['table_name']==='courses'})[0]['data']
	courses.map(course=>course_names.push(course.course_nazov))
	
	const database = database_data
	const allCompanies = company_names
	const allCompaniesInactive = company_inactive
	const allCourses_names = course_names
	const fullFetch = true


	const osoby_firmy = []
	let osoby_pridelenie1 = []
	const osoby_pridelenie2 = []
	// const osoby_courses = ["01.1a BOZP zamestnancov a vedúcich zamestnancov" ]
	const osoby_courses = allCourses_names
	// const osoby_datumy = ['zmeškané do dnes', 'aktuálny+nasledujúci mesiac']
	const osoby_datumy = ['zvoľ dátum']
	const headers = compose_header_personal_profiles(osoby_courses,allCourses)
	let conditions_dicts = setConditionDict()

	//******************************************** */
	//******************************************** */
	//what is there are profiles not subject  to any technician?
	//******************************************** */
	//******************************************** */

	console.log('sending remainders for ',niter,' months')
	
	const delay = 1200 // delay in miliseconds to delay the email sending 

	
	// for (let i=0;i<niter;i++){
	let emails2send = []
	let data_to_notify = []
	
	Array.from(Array(niter).keys()).map(async (i)=>{

		
		const month_to_deal = (last_update_month + i)%12 + 1
		console.log('i,last_update_month,i%12,(last_update_month + i)%12 + 1',i,last_update_month,i%12,(last_update_month + i)%12 + 1)
		const year_to_deal = Number(last_update_year) + Math.floor((last_update_month + i)/12)
		const month_to_deal_up_to = (last_update_month + i+1)%12 + 1
		const year_to_deal_up_to = Number(last_update_year) + Math.floor((last_update_month + i+1)/12)
		console.log('dealing with:',month_to_deal,year_to_deal)
		console.log('dealing up to end of:',month_to_deal_up_to,year_to_deal_up_to)

		console.log('before update:',conditions_dicts)
		conditions_dicts = set_manual_dates_into_conditions_dicts(month_to_deal_up_to,year_to_deal_up_to,JSON.parse(JSON.stringify(conditions_dicts)))
		console.log('after update:',conditions_dicts)
	
			
		technicians.map(async (technician,index)=>{

			console.log('dealing with technician:',technician)
			osoby_pridelenie1 = [technician]
			const filename = `notifikacia_${get_string_month(month_to_deal)}_${year_to_deal}_pre_${technician.split(" ")[0]}_${technician.split(" ")[1]}.xlsx`
			let data = arrangePersons(fullFetch,database,osoby_firmy,osoby_pridelenie1,osoby_pridelenie2,osoby_courses,osoby_datumy,allCourses,conditions_dicts)
			console.log('person data notifications for technician:',osoby_pridelenie1,data)
			
			let response = null
			if (data.length>0){
				const email = allTechnicians[index].technician_email
				console.log('sending email to',email)
				//get tabulated data from arrangePersons
				const arrangePersons_tab = get_data(headers,data)
				console.log('headers,tabulated data:',headers,arrangePersons_tab)
				const columns = get_columns_for_table(headers)
				console.log('columns',columns)
				const output = get_lines_for_table(columns,arrangePersons_tab)
				const lines_for_table = output[0]
				const columns_reduced = output[1]
				console.log('lines_for_table:',output,lines_for_table,columns_reduced)

				emails2send.push([columns_reduced,lines_for_table, email,`Notifikácia poslaná na ${email}. V prílohe sa nachádzajú nájdené záznamy pre: zmeškané do začiatku mesiaca ${month_to_deal} ${year_to_deal} + nasledujúce dva mesiace, teda do konca mesiaca ${month_to_deal_up_to} ${year_to_deal_up_to}`,filename])
				console.log('email will be sent to: ',email,' with attachment: ',filename,' and response: ',response)	
			}else{
				const email = allTechnicians[index].technician_email
				console.log('sending email to',email,' no work to be done!')
				//send email to a technician where none relevant data are included - no work to be done
				emails2send.push([null,null,'joz.bucko@gmail.com',`Notifikácia poslaná na ${email}. Nie sú zistené žiadne záznamy pre: zmeškané do začiatku mesiaca ${month_to_deal} ${year_to_deal} + nasledujúce dva mesiace, teda do konca mesiaca ${month_to_deal_up_to} ${year_to_deal_up_to}`,filename])
				console.log('empty email will be sent to: ',email,' with attachment: ',filename,' and response: ',response)	
			}
		})
		data_to_notify.push({'update_time': new Date(),'update_period':[get_string_month(month_to_deal),String(year_to_deal)].join(" "),'update_status':'to be determined'})
		console.log('technicians loop over, storing:',data_to_notify)
		
	
	})

	console.log('sending following ',emails2send.length, ' emails:',emails2send)
	// send all the email and then update the notifications table
	try{
		console.log('one huge send email promise goes here')
		const response_all_email = await DataService.sendEmailAllAtOnce(emails2send)
		console.log('response data:',response_all_email.data)
		try{
			//update notifications table
			for (let i=0;i<data_to_notify.length;i++){
				data_to_notify[i]['update_status'] = 'success'
				const response = await DataService.updateNotificationHistory(data_to_notify[i])
				// const response = null
				console.log('response from update notifications:',response)
			}
		}catch(e){
			console.log('notification update failed with error:',e, 'for period ', 'some month','some year')
			global_status = 'failed'
		}
	}catch(e){
		console.log('promise all emails error:',e)
		global_status = 'failed'

	}finally{
		console.log('returning global status:',global_status)
		return global_status
	}
	
	
}


function set_manual_dates_into_conditions_dicts(monthHigh,yearHigh,conditions_dicts){
	// console.log('in set_manual_dates_into_conditions_dicts:',monthHigh,yearHigh)
	const monthHighCorr = (monthHigh>9)?String(monthHigh):'0'+String(monthHigh)
	const yearHighCorr = String(yearHigh)
	let days={
		1: 31,
		2: 29,
		3: 31,
		4: 30,
		5: 31,
		6: 30,
		7: 31,
		8: 31,
		9: 30,
		10: 31,
		11: 30,
		12: 31,
	  }
	conditions_dicts['zvoľ dátum'] = {}
	conditions_dicts['zvoľ dátum'].lower = '11.11.1111'
	// console.log('putting in:',[days[monthHigh],monthHighCorr,yearHighCorr].join('.'))
	conditions_dicts['zvoľ dátum'].upper = [days[monthHigh],monthHighCorr,yearHighCorr].join('.')
	return conditions_dicts
}

function get_lines_for_table(columns,arrangePersons_tab){
	//delete empty columns
	let delete_columns = []
	columns.forEach((column,index)=>{
		let delete_column = true
		arrangePersons_tab.forEach(person=>{
			if (person[index] && person[index]!==""){
				delete_column = false
			}
		})
		if (delete_column === true){
			delete_columns.push(index)
		}
	})

	console.log('columns,columns to delete',columns,delete_columns)
	console.log('columns to keep',columns.filter((item,index)=>!delete_columns.includes(index)))
	let data_for_lines = []
	let columns_adjusted = []
	arrangePersons_tab.forEach(person=>{
		let dic = {}
		columns.forEach((column,index)=>{
			if (!delete_columns.includes(index)){
				dic[column.key] = person[index]
			}
		})
		data_for_lines.push(dic)
	})

	columns.forEach((column,index)=>{
		if (!delete_columns.includes(index)){
			columns_adjusted.push(columns[index])
		}
	})
	
	console.log('outputting data:',data_for_lines,columns_adjusted)
	return [data_for_lines,columns_adjusted]
}

function get_columns_for_table(headers){
	let columns = [{'header':'#','key':'index'}]
	headers.forEach(header=>{
		let dic = {'header':Object.values(header)[0], 'key': Object.keys(header)[0]}
		columns.push(dic)
	})
	return columns
}



function get_data(headers,arrangePersons_data){
	let data = []
	arrangePersons_data && arrangePersons_data.map((item,index_item)=>{
		
		data.push([index_item+1])
		headers.map((header,index)=>(data[index_item].push(item[Object.keys(header)[0]])))
	})
	return data
}

function get_string_month(month){
	let month_mapping = {
		1: "January",
		2: "February",
		3: "March",
		4: "April",
		5: "May",
		6: "June",
		7: "July",
		8: "August",
		9: "September",
		10: "October",
		11: "November",
		12: "December",
	}
	return month_mapping[Number(month)]
}

function get_number_month(month){
	let month_mapping = {
		"January":1,
		"February":2,
		"March":3,
		"April":4,
		"May":5,
		"June":6,
		"July":7,
		"August":8,
		"September":9,
		"October":10,
		"November":11,
		"December":12,
	}
	return month_mapping[month]
}



function create_timeout_promise(timeout){
	const a = new Promise((resolve,reject)=>{
		setTimeout(()=>{
			resolve(0)
		},timeout)
	})
	return a
}