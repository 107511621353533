// period is stated in months
const custom_os_aop = {
	'ine_co_precvicenie': {'os':36,'aop':null},
	'k01_1b': {'os':12,'aop':null},
	'k01_1e': {'os':12,'aop':null},
	'zz_bd2': {'os':12,'aop':null},
	'opp_mimopracovne': {'os':12,'aop':null},
	'opp_hasiace': {'os':12,'aop':null},
	'ine_ppp': {'os':3,'aop':null},
}

export default custom_os_aop