import http from "../http-common";
import axios from "axios";
import React, {Component} from "react";

class TutorialDataService {
  // constructor(username = window.sessionStorage.getItem("username"), password = window.sessionStorage.getItem("password")) {
  //   this.uname = username; 
  //   this.pwd = password;
  // }

  // uploadFile(file){
  //   return http.post("/tutorials/files")
  // }
  headers(){
    let header_dict = {
      'authorization': window.sessionStorage.getItem("username")+window.sessionStorage.getItem("password"),
    }
    return header_dict
  }

  migrate() {
    console.log('in migrate');
    return http.post("/tutorials/migrate",null,{headers: this.headers()});
  }
  
  updateNotificationHistory(data) {
    console.log('in updateNotificationHistory');
    console.log(data);
    return http.post("/tutorials/update_notifications", data, {headers: this.headers()});
  }

  sendEmail(data) {
    console.log('in sendEmail');
    console.log(data);
    return http.post("/tutorials/send_email", data, {headers: this.headers()});
  }

  sendEmailAllAtOnce(data) {
    console.log('in sendEmailAllAtOnce');
    console.log(data);
    return http.post("/tutorials/send_email_atonce", data, {headers: this.headers()});
  }

  verify_user(data) {
    console.log('in verify user');
    console.log(data);
    return http.post("/tutorials/verify_user", data);
  }

  getNotificationHistory() {
    return http.get("/tutorials/get_notifications",{headers: this.headers()});
  }

  getAllCourses() {
    return http.get("/tutorials/courses",{headers: this.headers()});
  }

  getWholeDatabase() {
    console.log('in getAll:')
    return http.get("/tutorials/all",{headers: this.headers()});
  }

  getAllCompanies() {
    return http.get("/tutorials/get_firmy",{headers: this.headers()});
  }

  getAllTechnicians() {
    return http.get("/tutorials/get_technicians",{headers: this.headers()});
    // return http.get("/tutorials/get_technicians");
  }

  archiveManually(data){
    console.log('in archiveManually')
    return http.post("/tutorials/archive",data,{headers: this.headers()});
  }

  getArchive(){
    console.log('in getArchive')
    return http.get("/tutorials/get_technicians_archive",{headers: this.headers()});
  }

  getAll() {
    return http.get("/tutorials",{headers: this.headers()});
  }

  get(id) {
    return http.get(`/tutorials/${id}`,{headers: this.headers()});
  }

  getCompanyById(id) {
    return http.get(`/tutorials/company/${id}`,{headers: this.headers()});
  }

  create(data) {
    console.log('in create');
    console.log(data);
    return http.post("/tutorials", data,{headers: this.headers()});
  }

  createCourse(data) {
    // to create data input in table courses
    console.log('in create course');
    console.log(data);
    return http.post("/tutorials/new_course", data,{headers: this.headers()});
  }

  handleCourse(data) {
    // to update data in existing tables = adding OS/AOP dates
    console.log('in create course2');
    console.log(data);
    return http.post("/tutorials/new_course2", data,{headers: this.headers()});
  }

  handleCourseZS_RS(data) {
    // to update data in existing tables = adding OS/AOP dates
    // console.log('in create handleCourseZS_RS');
    console.log('data in create handleCourseZS_RS',data);
    return http.post("/tutorials/handle_course_zs_rs", data,{headers: this.headers()});
  }

  updateCourse(data) {
    console.log('in update course');
    console.log(data);
    return http.post("/tutorials/update_course", data,{headers: this.headers()});
  }

  create_company(data) {
    console.log('in create company');
    console.log(data);
    return http.post("/tutorials/new_company", data,{headers: this.headers()});
  }

  createTechnician(data) {
    console.log('in createTechnician');
    console.log(data);
    return http.post("/tutorials/new_technician", data,{headers: this.headers()});
  }

  adjustTechnician(data) {
    console.log('in adjustTechnician');
    console.log(data);
    return http.post("/tutorials/adjust_technician", data,{headers: this.headers()});
  }

  upload(data) {
    console.log('in upload');
    console.log(data);
    // axios.post("/tutorials/uploadfile", data, {headers: {Accept: 'multipart/form-data'}});
    return http.post("/tutorials/uploadfile", data,{headers: this.headers()});
  }

  getFile(name) {
    console.log('in tutorial.service: getFile name:',name)
    return http.get(`/tutorials/getfile/${name}`,{headers: this.headers()})
  }

  update(id, data) {
    console.log('in tutorial services:',id,data);
    return http.put(`/tutorials/updateprofile/${id}`, data,{headers: this.headers()});
  }

  update_company(id, data) {
    console.log('in tutorial services:',id,data);
    return http.put(`/tutorials/updatecompany/${id}`, data,{headers: this.headers()});
  }

  delete(id) {
    return http.delete(`/tutorials/${id}`,{headers: this.headers()});
  }

  deleteTech(id) {
    return http.delete(`/tutorials/delete_tech/${id}`,{headers: this.headers()});
  }

  deleteCompany(id) {
    return http.delete(`/tutorials/delete_company/${id}`,{headers: this.headers()});
  }
  
  deleteCourse(id) {
    console.log('in delete course')
    return http.delete(`/tutorials/delete_course/${id}`,{headers: this.headers()});
  }

  deleteAll() {
    return http.delete(`/tutorials`,{headers: this.headers()});
  }

  findByName(name) {
    console.log('in find by name',name)
    return http.get(`/tutorials?title=${name}`,{headers: this.headers()});
  }

  findCompanyByName(name) {
    console.log('in find company by name',name)
    return http.get(`/tutorials/company/${name}`,{headers: this.headers()});
  }

}

export default new TutorialDataService();